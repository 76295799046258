<template>
    <div class="three_cross_report report_content">
        <div class="filter" v-if="tab<=0">
            <div class="search">
                <el-date-picker size="small" v-model="month" type="month" placeholder="选择月度" value-format="yyyy-MM"
                                :clearable="false" style="width:118px;" @change="reportState"></el-date-picker>
            </div>
        </div>
        <div class="content" v-if="tab<=0">
            <h3>{{ show_month.year }}年{{ show_month.month }}月各县填报情况</h3>
            <div class="report_table">
                <div class="table">
                    <el-table :data="tableData" style="width: 100%;" height="100%">
                        <el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
                        <el-table-column label="地区" align="center" prop="label"></el-table-column>
                        <el-table-column label="是否填报" align="center">
                            <template slot-scope="scope">
                                <div>
                                    <span v-if="scope.row.count>0" style="color: #07BA3D">是</span>
                                    <span v-else style="color: #F56C6C">否</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="填报数量" align="center" prop="count"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="tabChange(scope.row.value)"
                                           v-if="show_btn(three_cross_power.area,scope.row)">去填报
                                </el-button>
                                <div v-else>--</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!--填报表-->
        <div class="filter" v-if="tab>0">
            <div class="search">
                <el-button type="text" icon="el-icon-arrow-left" @click="back">返回</el-button>
            </div>
            <el-button size="small" type="primary" class="button_98px" @click="exportForm"
                       :disabled="tableData2.filter(a=>a.id).length<=0">导出表格
            </el-button>
        </div>
        <div class="content" v-if="tab>0">
            <h3>援青交往、交流、交融项目实施计划报备表</h3>
            <h4>（{{ show_month.year }}年{{ show_month.month }}月份）</h4>
            <div class="report_table">
                <div class="table">
                    <el-table :data="tableData2" style="width: 100%;" border height="100%">
                        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                        <el-table-column label="所属县或州直单位" width="86" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.address }}</div>
                                <el-input v-else size="mini" v-model="formData.address" placeholder="所属地"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="项目名称" width="120" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.project_name }}</div>
                                <el-input v-else size="mini" v-model="formData.project_name"
                                          placeholder="项目名称"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="援助（受援）实施单位" width="120" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.company }}</div>
                                <el-input v-else size="mini" v-model="formData.company" placeholder="单位名称"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="计划实施时间" width="120" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.plan_start_time }}</div>
                                <el-date-picker v-else value-format="yyyy-MM-dd 00:00:00" v-model="formData.plan_start_time" type="date"
                                                size="mini" placeholder="实施时间"></el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="计划完成时间" width="120" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.plan_finish_time }}</div>
                                <el-date-picker v-else value-format="yyyy-MM-dd 00:00:00" v-model="formData.plan_finish_time" type="date"
                                                size="mini" placeholder="完成时间"></el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="项目援助（受援）单位计划来（去）青（沪）时间" min-width="120" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.go_time }}</div>
                                <el-date-picker v-else value-format="yyyy-MM-dd 00:00:00" v-model="formData.go_time" type="date"
                                                size="mini" placeholder="来（去）青（沪）时间"></el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="项目援助（受援）单位计划返沪（青）时间" min-width="120" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.return_time }}</div>
                                <el-date-picker v-else value-format="yyyy-MM-dd 00:00:00" v-model="formData.return_time" type="date"
                                                size="mini" placeholder="返沪（青）时间"></el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="项目援助（受援）团队计划来（去）青（沪）人数" width="100" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">
                                    <span
                                        v-if="scope.row.go_number!==''&&scope.row.go_number!==null&&scope.row.go_number!==undefined">{{
                                            scope.row.go_number
                                        }}人</span>
                                </div>
                                <el-input-number v-else size="mini" v-model="formData.go_number"
                                                 :precision="0" :step="1" :controls="false"
                                                 placeholder="填写人数"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column label="项目组织参加人数" width="100" align="center">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">
                                    <span
                                        v-if="scope.row.join_people_number!==''&&scope.row.join_people_number!==null&&scope.row.join_people_number!==undefined">{{
                                            scope.row.join_people_number
                                        }}人</span>
                                </div>
                                <el-input-number v-else size="mini" v-model="formData.join_people_number"
                                                 :precision="0" :step="1" :controls="false"
                                                 placeholder="填写人数"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column label="联系人" align="center" width="84">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.contact_people }}</div>
                                <el-input v-else size="mini" v-model="formData.contact_people"
                                          placeholder="联系人"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="联系电话" align="center" width="120">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.contact_phone }}</div>
                                <el-input v-else size="mini" v-model="formData.contact_phone"
                                          placeholder="联系电话"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="备注" align="center" width="100">
                            <template slot-scope="scope">
                                <div v-if="formData.id!=scope.row.id">{{ scope.row.remark }}</div>
                                <el-input v-else size="mini" v-model="formData.remark" placeholder="备注"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120" align="center" v-if="three_cross_power.power.edit">
                            <template slot-scope="scope">
                                <div v-if="!formData.id&&scope.row.id">
                                    <el-button type="text" @click="clickEdit(scope.row)">编辑</el-button>
                                    <el-button type="text" @click="del(scope.row,scope.$index)">删除
                                    </el-button>
                                </div>
                                <div v-if="scope.row.id&&formData.id==scope.row.id">
                                    <el-button type="text" @click="addAndEdit">保存</el-button>
                                    <el-button type="text" @click="cancelEdit">取消</el-button>
                                </div>
                                <el-button type="text" icon="el-icon-plus" @click="addAndEdit"
                                           v-if="!scope.row.id">添加
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import myPublic from '@/utils/public.js';

export default {
    data() {
        return {
            month: '', tableData: [],
            tab: 0,//0:各县填报情况,其他数字:三交项目实施计划报备列表,对应list里的value值
            area: '', tableData2: [],
            formData: {},
        };
    },
    computed: {
        default_data() {
            return {
                address: '',
                project_name: '',
                company: '',
                plan_start_time: '',
                plan_finish_time: '',
                go_time: '',
                return_time: '',
                go_number: undefined,
                join_people_number: undefined,
                contact_people: '',
                contact_phone: '',
                remark: '',
            };
        },
        three_cross_power() {
            return this.$store.state.user.current_user.three_cross_power;
        },
        show_month() {
            let year = '', month = '';
            if (this.month) {
                let arr = this.month.split('-');
                if (arr[0]) {
                    year = arr[0];
                }
                if (arr[1]) {
                    month = arr[1];
                }
            }
            return {year, month};
        },
        //显示去填报的按钮
        show_btn: () => (area, row) => {
            let bol = false;
            if (!area || area == -1) {
                bol = true;
            } else if (area > 0) {
                if (area == row.value) {
                    bol = true;
                }
            }
            return bol;
        }
    },
    created() {
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        if (month < 10) month = '0' + month;
        this.month = year + '-' + month;
        this.reportState();
    },
    methods: {
        //各县填报情况
        reportState() {
            let year = this.show_month.year, month = this.show_month.month;
            api.threeCross.reportState({year, month}).then(res => {
                let list = [
                    {value: 1, label: '州本级', count: 0},
                    {value: 2, label: '玛沁县', count: 0},
                    {value: 5, label: '甘德县', count: 0},
                    {value: 6, label: '达日县', count: 0},
                    {value: 4, label: '班玛县', count: 0},
                    {value: 3, label: '久治县', count: 0},
                    {value: 7, label: '玛多县', count: 0},
                ];
                if (res.data) {
                    let arr = res.data.data || [];
                    for (let item of list) {
                        let obj = arr.find(a => a.area == item.value);
                        if (obj) {
                            item.count = obj.count || 0;
                        }
                    }
                }
                this.tableData = list;
            });
        },
        tabChange(e) {
            this.tab = e;
            this.formData = JSON.parse(JSON.stringify(this.default_data));
            this.getList();
        },
        back() {
            this.tab = 0;
            this.reportState();
        },
        //获取填报列表
        getList(load = true) {
            if (load != false) {
                this.tableData2 = [];
            }
            let param = {
                page: 1,
                page_size: 9999,
                area: this.tab,
                year: this.show_month.year,
                month: this.show_month.month,
            };
            api.threeCross.threeCrossList(param).then(res => {
                let list = [];
                if (res.data && res.data.data) {
                    list = res.data.data.lists || [];
                    list.forEach(item => {
                        if (!item.address) {
                            item.address = '';
                        }
                        if (!item.project_name) {
                            item.project_name = '';
                        }
                        if (!item.company) {
                            item.company = '';
                        }
                        if (item.plan_start_time) {
                            item.plan_start_time = myPublic.dateFormat(item.plan_start_time, 'YYYY-MM-DD');
                        } else {
                            item.plan_start_time = '';
                        }
                        if (item.plan_finish_time) {
                            item.plan_finish_time = myPublic.dateFormat(item.plan_finish_time, 'YYYY-MM-DD');
                        } else {
                            item.plan_finish_time = '';
                        }
                        if (item.go_time) {
                            item.go_time = myPublic.dateFormat(item.go_time, 'YYYY-MM-DD');
                        } else {
                            item.go_time = '';
                        }
                        if (item.return_time) {
                            item.return_time = myPublic.dateFormat(item.return_time, 'YYYY-MM-DD');
                        } else {
                            item.return_time = '';
                        }
                        if (item.go_number == undefined || item.go_number == null || item.go_number == '') {
                            item.go_number = undefined;
                        } else {
                            item.go_number = Number(item.go_number);
                        }
                        if (item.go_number == undefined || item.go_number == null || item.go_number == '') {
                            item.go_number = undefined;
                        } else {
                            item.go_number = Number(item.go_number);
                        }
                        if (item.join_people_number == undefined || item.join_people_number == null || item.join_people_number == '') {
                            item.join_people_number = undefined;
                        } else {
                            item.join_people_number = Number(item.join_people_number);
                        }
                        if (!item.contact_people) {
                            item.contact_people = '';
                        }
                        if (!item.contact_phone) {
                            item.contact_phone = '';
                        }
                        if (!item.remark) {
                            item.remark = '';
                        }
                    });
                }
                if (this.three_cross_power.power.edit) {
                    this.tableData2 = [...list, {...JSON.parse(JSON.stringify(this.default_data))}];
                } else {
                    this.tableData2 = [...list];
                }
            });
        },
        //点击修改
        clickEdit(row) {
            this.formData = JSON.parse(JSON.stringify(row));
            let index = this.tableData2.findIndex(a => !a.id);
            if (index != -1) {
                this.tableData2.splice(index, 1);
            }
        },
        //取消修改
        cancelEdit() {
            this.formData = JSON.parse(JSON.stringify(this.default_data));
            this.tableData2.push(JSON.parse(JSON.stringify(this.default_data)));
        },
        //新增或修改
        addAndEdit() {
            let param = {
                year: this.show_month.year,
                month: this.show_month.month,
                area: this.tab,
            };
            if (this.formData.id) {
                param.id = this.formData.id;
            }
            if (this.formData.address) {
                param.address = this.formData.address;
            }
            if (this.formData.project_name) {
                param.project_name = this.formData.project_name;
            }
            if (this.formData.company) {
                param.company = this.formData.company;
            }
            if (this.formData.plan_start_time) {
                param.plan_start_time = this.formData.plan_start_time;
            }
            if (this.formData.plan_finish_time) {
                param.plan_finish_time = this.formData.plan_finish_time;
            }
            if (this.formData.go_time) {
                param.go_time = this.formData.go_time;
            }
            if (this.formData.return_time) {
                param.return_time = this.formData.return_time;
            }
            if (this.formData.go_number != undefined && this.formData.go_number != null && this.formData.go_number != '') {
                param.go_number = this.formData.go_number;
            }
            if (this.formData.join_people_number != undefined && this.formData.join_people_number != null && this.formData.join_people_number != '') {
                param.join_people_number = this.formData.join_people_number;
            }
            if (this.formData.contact_people) {
                param.contact_people = this.formData.contact_people;
            }
            if (this.formData.contact_phone) {
                param.contact_phone = this.formData.contact_phone;
            }
            if (this.formData.remark) {
                param.remark = this.formData.remark;
            }
            api.threeCross.threeCrossSave(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: `记录${param.id ? '修改' : '添加'}成功`,
                        type: 'success'
                    });
                    this.formData = JSON.parse(JSON.stringify(this.default_data));
                    this.getList(false);
                }
            });
        },
        del(row, index) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, `确定要删除第${index + 1}行数据吗?`),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.threeCross.threeCrossDel({id: row.id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '数据删除成功',
                                    type: 'success'
                                });
                                this.getList(false);
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        exportForm() {
            let param = {
                page: 1,
                page_size: 9999,
                area: this.tab,
                year: this.show_month.year,
                month: this.show_month.month,
            };
            let _url = window.location.href;
            let __url = _url.split("#")[0];
            let url = __url + "index.php/cqh/Threeproject/lists_out/?token=" + this.$store.state.user.token + "&area=" + param.area + "&year=" + param.year + "&month=" + param.month + "&page=1&page_size=9999";
            api.threeCross.threeCrossReport(param).then(res => {
                dd.biz.util.openLink({
                    url, //要打开链接的地址
                    onSuccess: function (result) {
                        console.log('打开导出地址成功');
                    },
                    onFail: function (err) {
                        console.log('打开导出地址失败', err);
                    }
                });
            });
        },
    }
};
</script>

<style scoped lang="less">
.three_cross_report {
    h4 {
        text-align: center;
        padding-bottom: 10px;
    }

    /deep/ .el-table {
        .el-date-editor {
            .el-input__prefix {
                display: none !important;
            }

            .el-input__suffix {
                right: 0;
                font-size: 14px;
            }
        }
    }
}
</style>